<template> 
  <div>
    <Loader text="Ładowanie strony" v-if="!user.id" />

    <div class="container pt-4" v-if="user.id">

      <div class="pt-3 text-center">
        <h1>Brak dostępu</h1>
        <h5>Nie masz dostępu do tego modułu</h5>
      </div>

    </div>
  </div>
</template>

<script>
import Loader from '../../elements/Loader';

export default {
  name: 'AccessDenied',
  components: {
     Loader
  },
  data() {
    return {
      user: {
        id: null,
        permissions: []
      }
    }
  },

  created() {
    
    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data 

      if(data.redirectTo) {

        top.location.href = data.redirectTo;

      }

      if(data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions

      }

    });

  },
}
</script>

 