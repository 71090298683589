<template> 
  <div class="bb" v-if="path.length > 0">
    <div class="container pt-2 pb-2">
      <small>
        <span v-for="item in path" :key="item.label" >
          <b-button :to="item.link" variant="light" size="sm" v-if="item.label" style="font-size: 0.8rem;">{{ item.label }}</b-button> &nbsp;
        </span> 
      </small>

      <b-alert variant="success" show class="mt-2">
        Jesteś w naszej ASM? Promocyjna oferta 12 Coachingów z Mirkiem Skwarkiem na Zoom. <A href="https://op.funnelstar.io/akademia-social-media-coaching-miroslaw-skwarek/"><u>Kliknij tutaj ></u></A>
      </b-alert>

    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadCumber',
  data() {
    return {
      path: [],
    }
  },
  created() {
    
    this.$root.$on('system.breadcumber.set', (path) => {
       this.path = path
    }); 

  },
}
</script>

