<template> 
  <div class="container pt-4 text-center">
    <b-spinner variant="secondary" small></b-spinner> {{ text }}
  </div>
</template>

<script>
  export default {
    name: 'Loader',
    props: ['text']
  }
</script>

 